import React, {Component} from 'react';
import Helmet from 'react-helmet'
import lib from 'library'

import TitleBar from 'components/TitleBar.js'
import SectionContact from 'components/SectionContact.js'
import Layout from "components/layout"

export default class App extends Component {
  render(){

    const lang = lib.lc.currentLang(this.props.location.pathname)

    return(

      <Layout pathname={this.props.location.pathname}>

          <div >
            <Helmet
              title={lc[lang].pagename}
              meta={[
                { name: 'description', content: '冠信控股有限公司成立於2014年，主要業務是代工生產箱包、背包和旅行配件。' },
                { name: 'keywords', content: '冠信控股有限公司, 冠信, 行李箱, 背包, 旅行配件' },
              ]}
            />

            <div style={{height: 50}} />
            <TitleBar title={lc[lang].companyinfo} />
              <SectionContact pathname={this.props.location.pathname} />
              <div style={{height: 100}} />

          </div>
</Layout>
    )
  }
}


const lc = {
  en:{
    pagename: 'Contact us - Kun Shung Holdings Limited',
    contactus: 'Contact us',
  },
  zh:{
    pagename: '聯絡我們 - 冠信控股有限公司',
    contactus: '聯絡我們',
  }
}
